import React from "react";
import {PortableText} from "@portabletext/react";
import {imageUrl} from "gatsby-plugin-sanity-image";

const Product = ({product}) => {
    let imgUrl = product.image?.asset ? imageUrl( product.image?.asset ) : 'https://placehold.jp/500x500.png?text=No%20Picture'

    return (
        <a href={product.link} className="item card full-bg"
           style={{backgroundImage: "url("+imgUrl+")"}}>
            <div className="label">
                <div className="subtitle">
                    {product.title}
                </div>
            </div>
            <div style={{flexGrow: 1}}></div>
            <div className="label">
                <PortableText value={product._rawDescription} onMissingComponent={console.log}/>
            </div>
        </a>
    )
}
export default Product
