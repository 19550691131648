import React from "react"

import { graphql, useStaticQuery } from "gatsby"

export const useSiteMetadata = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

    return data.site.siteMetadata
}

export const SEO = ({ title, description, pathname, customImage, children }) => {
    const { title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername } = useSiteMetadata()

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image}`,
        url: `${siteUrl}${pathname || ``}`,
        twitterUsername,
    }

    return (
        <>
            {/*<title>{seo.title}</title>*/}
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="og:title" content={seo.title} />
            <meta name="og:url" content={seo.url} />
            <meta name="og:description" content={seo.description} />
            <meta name="twitter:image" content={customImage ?? seo.image} />
            <link rel="icon" href="/favicon.ico" />
            {children}
        </>
    )
}