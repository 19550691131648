import {graphql} from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Product from "../components/Product";
import {PortableText} from "@portabletext/react";
import {imageUrl} from "gatsby-plugin-sanity-image";
import {SEO} from "../components/SEO";

export default function Category({data}) {
    let imgUrl = data.sanityCategory.image?.asset ? imageUrl( data.sanityCategory.image?.asset ) : 'https://placehold.jp/500x500.png?text=No%20Picture'

    return (
        <Layout title={data.sanityCategory.title}>
            <Banner heightSmall={true} heirarchy={[["Home", "/"], ["Products", "/products"], [data.sanityCategory.title, "/categories/"+data.sanityCategory._id]]}>
                <div style={{color: "white", width: "50%"}}>
                    <h1 style={{fontSize: "3rem", fontWeight: "bold"}}>{data.sanityCategory.title}</h1>
                </div>

            </Banner>

                <div className={"section grid-2"} style={{"background":"#e9eff4"}}>
                    <div className={"full-bg"} style={{backgroundImage: `url(${imgUrl})`, height: "300px"}}></div>
                    <div className={"big-p"}>
                        <PortableText value={data.sanityCategory._rawDescription} />
                    </div>
                </div>
            <article className="section" style={{gap: "50px"}}>
                        <div>
                            <h2 style={{textAlign: "center", padding: "10px"}}>Products</h2>

                            <div className={"main-grid"}>
                                {data.sanityCategory.products.map(p=>{
                                    return (
                                        <Product product={p}></Product>
                                    )
                                })}
                            </div>
                        </div>
            </article>

            <div style={{"background":"#00275b","color":"white","display":"flex","justifyContent":"center","alignItems":"center","fontSize":"x-large","padding":"20px","height":"150px"}}>
                <div>
                    Visit our <a href="https://pacific-technology.co.jp/en/service/ceramics/products/various-ceramics/ "
                                 style={{color: "lightblue", textDecoration: "underline"}}> Japan Site</a> for more details
                </div>
            </div>

        </Layout>
    )
}

export const query = graphql`
query (
    $id: String
  ) {
    sanityCategory(_id: { eq: $id }) {
    _id
      title
        image {
          ...ImageWithPreview
        }
        _rawDescription
          products {
            _id
            title
            _rawDescription
            image {
              ...ImageWithPreview
            }
            link
          }
    }
  }
`

export const Head = ({data}) => {
    let imgUrl = data.sanityCategory.image?.asset ? imageUrl( data.sanityCategory.image?.asset ) : 'https://placehold.jp/500x500.png?text=No%20Picture'

    return (
        <SEO customImage={imgUrl} title={data.sanityCategory.title}></SEO>
    )
}